export const DEFAULT_ATTACHMENT_TYPE = 'case_file'
export const DEFAULT_TEMPLATE_TARGET = 'case_file'

export const DEFAULT_LANGUAGE = 'de'
export const DEFAULT_TONALITY = 'no_tonality'
export const DEFAULT_COUNTRY = 'universal'
export const MANUAL_SEND_GROUP = 'manual'

export const DEFAULT_PAIR_ENVIRONMENT = 'nl'
export const SLUG_NUMBER_TO_ENVIRONMENT_MAP = {
  '1': 'de',
  '2': 'at',
  '3': 'ch',
  '4': 'nl',
  '5': 'se',
  '6': 'pl'
}

export const DEFAULT_PREVIEW_FILE_NAME = 'preview.pdf'

export const PLAIN_TEXT_TEMPLATE_KINDS = ['sms', 'plain']
export const TEMPLATE_WITHOUT_CONTENT_FIELD_KINDS = ['mjml', 'html']

export const ATTACHMENT_URL_VARIABLE = '{% attachment_file_url {id} %}'
export const DEBTOR_UUID_VARIABLE = '{{ primary_debtor.uuid }}'
export const DOMAIN_VARIABLE = '{{ domain }}'

export const TREE_KINDS_WITH_ITEMS_TO_SORT = [
  'variables',
  'snippets',
  'images',
  'links'
]

export const SNIPPET_TEMPLATE_KINDS = ['plain', 'mjml', 'html']
